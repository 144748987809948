<template>
  <v-container>
    <v-row class="justify-center">
      <v-card>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-text-field
              v-model="oldPassword"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Senha antiga"
              hint="Pelo menos 8 caracteres"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field
              v-model="newPassword"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rulesNew.required, rulesNew.min]"
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              label="Nova Senha"
              hint="Pelo menos 8 caracteres"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>

            <v-btn @click="submit" color="primary" block :disabled="!valid">
              Confirmar
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Profile",
  data: () => {
    return {
      valid: true,

      show1: false,
      show2: false,
      oldPassword: null,
      newPassword: null,
      id: null,
      rules: {
        required: (value) => !!value || "Informe a senha antiga.",
        min: (v) => v.length >= 8 || "Pelo menos 8 caracteres",
      },

      rulesNew: {
        required: (value) => !!value || "Informe a nova senha.",
        min: (v) => v.length >= 8 || "Pelo menos 8 caracteres",
      },
    };
  },
  created() {
    this.getPermission();
  },
  methods: {
    async getPermission() {
      try {
        const response = await this.$http.get("profile");
        this.id = response.data.id;
      } catch (error) {
        if (error.response.status === 500) {
          window.localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          window.localStorage.removeItem("token");
          this.$router.push("/");
          this.$toast.error("Falha no login");
        }
      }
    },

    async submit() {
      try {
          let user = {
        id: this.id,
        old_senha: this.oldPassword,
        senha: this.newPassword
      };
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        await this.$http.put("/user", {
          user,
        });

      }
                    this.$toast.success("Senha atualiza com sucesso!")

      } catch (error) {
           if (error.response.status === 403) {
            this.$toast.error("A Senha antiga está incorreta, tente novamente!", {
              position: "top-right",
            });
          } 
      }
    
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
</style>